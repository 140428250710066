import { gql } from "apollo-boost";


export const userFieldsShort = gql`
    fragment userFieldsShort on User {
        id
        nickname
        name
        initials
        avatar
        status
    }`;

export const userFields = gql`
    fragment userFields on User {
        ...userFieldsShort
        email
        locale
        role
        gender
        about
        last_active_date
    }
    ${ userFieldsShort }
`;

export const userMetaFields = gql`
    fragment userMetaFields on UserMeta {
        id
        meta_key
        meta_type
        meta_value
    }`;

export const userMetaFragment = gql`
    fragment userMeta on User {
        meta_fields{
            ...userMetaFields
        }
    }
    ${ userMetaFields }
`;

