import {gql} from "apollo-boost";

export const GET_DRAFT_POSTS = gql`
    query GetDraftPosts($status: PostStatus!, $author_id: ID, $endCursor : String){
        posts(status: $status, author_id: $author_id, after: $endCursor, first: 12){
            pageInfo{
              count
              total
              endCursor
            }
            edges{
                 node{
                    id
                    title
                    description
                    slug
                    status
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        status
                        order
                    }
                }
            }
        }
    }`;

export const GET_PUBLIC_POSTS = gql`
    query GetPublicPosts($status: PostStatus!, $author_id: ID, $endCursor : String){
        posts(status: $status, author_id: $author_id, after: $endCursor, first: 12){
            pageInfo{
              hasNextPage
              count
              total
              endCursor
            }
            edges{
                 node{
                    id
                    title
                    description
                    slug
                    status
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        status
                        order
                    }
                    like_count
                    comment_count
                    attached_file_count
                }
            }
        }
    }`;
