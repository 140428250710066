import React from "react";
import { Icon } from "antd";

const VideoSrokeSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M12.1,1.9C6.5,1.9,1.9,6.5,1.9,12.1s4.5,10.1,10.1,10.1s10.1-4.5,10.1-10.1S17.7,1.9,12.1,1.9z M12.1,20.7 c-4.8,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6c4.8,0,8.6,3.9,8.6,8.6S16.8,20.7,12.1,20.7z" />
      <path d="M16.3,11.2l-5.2-3c-0.4-0.3-1-0.3-1.4,0C9.2,8.4,8.9,8.9,8.9,9.4v6c0,0.5,0.3,1,0.7,1.2c0.2,0.1,0.5,0.2,0.7,0.2 s0.5-0.1,0.7-0.2l5.2-3c0.4-0.3,0.7-0.7,0.7-1.2C17,11.9,16.7,11.5,16.3,11.2z M10.4,15.3V9.6l4.9,2.8L10.4,15.3z" />
    </g>
  </svg>
);

const VideoSrokeIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={VideoSrokeSvg} />;
};

export default VideoSrokeIcon;
