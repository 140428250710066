import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input } from "antd";

import { FORGOT_PASSWORD } from "graphql/mutation/user-gql";

import { errorNotification } from "components/result";
import { Localize } from "components/service";

import './forgot-password-form.css';


const submitForm = (props) => {

    const { event, forgotRequest, form } = props;

    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {
            forgotRequest({
                variables : {
                    input: {
                        email: values.email,
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    });
};

const NormalForgotPasswordForm = (props) => {

    const {
        form,
        logo : Logo,
        sendEmail,
        loading,
        forgotRequest

    } = props;

    const { getFieldDecorator } =  form;

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder">
            <Logo />
            <h2>
                <Localize>FORMS.Title_ForgotPassword</Localize>
            </h2>
            <>
                { sendEmail ?
                    <div className="forgot-form-message">
                        <p>
                            <Localize>FORMS.Success_Text_ForgotPassword</Localize>
                        </p>
                    </div>:
                    <Form
                        className="forgot-password-form"
                        onSubmit={
                            (event) => {

                                submitForm({
                                    event,
                                    form,
                                    forgotRequest
                                })
                            }
                        }
                    >
                        <Form.Item
                            label={
                                <span className="site-label-text-color">
                                    <Localize>FORMS.Input_Label_Email</Localize>
                                </span>
                            }
                            {...formItemLayout}
                        >
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: <Localize>FORM_RULES.Email</Localize>,
                                    },
                                    {
                                        required: true,
                                        message: <Localize>FORM_RULES.Required_Email</Localize>
                                    }
                                ],
                            })(
                                <Input name="email" />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <div className="form-actions">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="rounded-button"
                                    loading={loading}
                                >
                                    <Localize wrap>FORMS.Button_Text_ForgotPassword</Localize>
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                }
            </>
            <div className="sign-link-wrap">
                <span className="site-label-text-color">
                    <Localize>FORMS.Text_Registration_Question</Localize>
                </span>
                <Link to="/registration/" className="company-color">
                    <Localize>FORMS.Link_Text_Registration</Localize>
                </Link>
            </div>
        </div>
    );

};

const ForgotPasswordFormContainer = (props) => {

    const [ sendEmail, setSendEmail ] = useState( false );

    const [forgotRequest, {loading}] = useMutation( FORGOT_PASSWORD,
        {
            update(cache, { data : { forgotPassword } }) {

                const { status } = forgotPassword;

                if(status === "EMAIL_SENT") {
                    setSendEmail(true);
                }

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <NormalForgotPasswordForm
            {...props}
            forgotRequest={forgotRequest}
            sendEmail={sendEmail}
            loading={ loading }
        />
    );
};


const ForgotPasswordForm = Form.create({ name: 'forgot-password' })(ForgotPasswordFormContainer);

export default ForgotPasswordForm;