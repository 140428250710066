import React from "react";
import { NavLink } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";

import { GET_ME } from "graphql/query/user-gql";

import Icons from "../../icons";

import './main-bar-mobile.css';


const MainBarMobile = () => {

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    return(
        <div className="main-bar-mobile header-bg-color header-color">
            <ul className="bar-actions">
                <li>
                    <NavLink to="/" title="News" className="bar-news" exact>
                        <Icons.News />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/timeline" title="My Timeline" className="bar-timeline" exact>
                        <Icons.Home type="timeline" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/chat" className="header-action-chat" exact>
                        <Icons.Chat type="menu" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/search" title="Search" className="bar-search">
                        <Icons.Search />
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${me.nickname}`} title="Profile" className="bar-profile">
                        <Icons.User />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/post/create"
                        title="Create post"
                        className="bar-create-post company-bg-color with-font-color"
                    >
                        <Icons.Plus />
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default MainBarMobile;