import React from "react";
import { Icon } from "antd";

const VideoFillSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M12.1,1.9C6.5,1.9,1.9,6.5,1.9,12.1s4.5,10.1,10.1,10.1s10.1-4.5,10.1-10.1S17.7,1.9,12.1,1.9z M16.2,13l-2.9,1.7l-2.9,1.7 c-0.5,0.3-1.1-0.1-1.1-0.6v-3.3V9.1c0-0.6,0.6-0.9,1.1-0.6l2.9,1.7l2.9,1.7C16.6,12.1,16.6,12.8,16.2,13z" />
  </svg>
);

const VideoFillIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={VideoFillSvg} />;
};

export default VideoFillIcon;
