// Обновленный CardMediaSlider.js
import React, { useState, useEffect } from "react";
import { Carousel, Modal } from "antd";
import MagicSliderDots from "react-magic-slider-dots";

import { Loader } from "../../result";
import Icons from "../../icons";
import videoProcessingImg from "assets/video-cover-processing.svg";
import { Localize } from "components/service";

import "./card-media-slider.css";
import "react-magic-slider-dots/dist/magic-dots.css";

const formatMedia = (media) => {
  return media.map((item) => {
    if (item.status !== null && !item.image_sizes) {
      return {
        ...item,
        image_sizes: [
          {
            thumbnail: item.thumbnail,
            original: item.path,
          },
        ],
      };
    }
    return item;
  });
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Icons.ArrowRight circle />
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Icons.ArrowLeft circle />
    </button>
  );
};

const ViewOriginalImage = ({ originalSrc }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    setImageLoading(true);
  }, [originalSrc, showPopup]);

  return (
    <div className="view-original-image company-icon-hover">
      <button className="site-icon" onClick={() => setShowPopup(true)}>
        <Icons.Scale />
      </button>
      <Modal
        closeIcon={<Icons.Close />}
        destroyOnClose={true}
        footer={null}
        visible={showPopup}
        closable={true}
        onCancel={() => {
          setShowPopup(false);
        }}
        wrapClassName="original-image-modal"
        centered
      >
        <div
          className={`original-image-wrap company-icon-hover ${
            imageLoading ? "loading" : ""
          }`}
        >
          <img src={originalSrc} alt="" onLoad={() => setImageLoading(false)} />
          {imageLoading ? (
            <Loader />
          ) : (
            <a
              href={originalSrc}
              download
              className="original-image-download site-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.Download />
            </a>
          )}
        </div>
      </Modal>
    </div>
  );
};

const CardMediaSlider = ({ media, postId, badge }) => {
  const [currentImg, setCurrentImg] = useState(null);
  const [isPlaying, setIsPlaying] = useState({});

  const showOriginalImage =
    currentImg && !currentImg.hasAttribute("data-video");

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: () => {
      setCurrentImg(
        document.querySelector(`#card-post-${postId} .slick-current img`)
      );
    },
    appendDots: (dots) => {
      return (
        <MagicSliderDots
          dots={dots}
          numDotsToShow={5}
          dotWidth={13}
          dotContainerClassName="magic-dots slick-dots slick-dots-bottom"
        />
      );
    },
  };

  if (!media.length) {
    return false;
  }

  const formattedMedia = formatMedia(media);

  const Slides = formattedMedia.map(
    ({ id, path, image_sizes, name, description, thumbnail, status }) => {
      const { original } = image_sizes[0];

      if (status !== null) {
        return (
          <div className={`card-image video ${status}`} key={id}>
            {isPlaying[id] && status === "loaded" ? (
              <video
                autoPlay
                // muted
                controls
                width="100%"
                height="auto"
                src={path}
                onEnded={() => setIsPlaying({ ...isPlaying, [id]: false })}
              />
            ) : (
              <>
                {status === "loaded" && (
                  <div
                    className="play-button"
                    onClick={() => setIsPlaying({ ...isPlaying, [id]: true })}
                  >
                    <Icons.PlayVideoArrow />
                  </div>
                )}

                {thumbnail && status === "loaded" ? (
                  <img src={thumbnail} data-video alt={description} />
                ) : (
                  status !== "loaded" && (
                    <>
                      <img
                        style={{ width: "250px" }}
                        src={videoProcessingImg}
                        data-video
                        alt="video-processing"
                      />
                      <div className="card-description-holder">
                        {status === "failed" ? (
                          <span className="card-description red-text">
                            <Localize>POST.Slider_Text_VideoFailed</Localize>
                          </span>
                        ) : (
                          <span className="card-description">
                            <Localize>POST.Slider_Text_VideoLoading</Localize>
                          </span>
                        )}
                      </div>
                    </>
                  )
                )}
              </>
            )}

            {!!description && (
              <div className="card-description-holder">
                <span className="card-description"> {description} </span>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="card-image" key={id}>
            <img
              src={path}
              alt={name}
              data-original-src={original}
              onLoad={() => {
                setCurrentImg(
                  document.querySelector(
                    `#card-post-${postId} .slick-current img`
                  )
                );
              }}
            />
            {!!description && (
              <div className="card-description-holder">
                <span className="card-description"> {description} </span>
              </div>
            )}
          </div>
        );
      }
    }
  );

  return (
    <div className="card-media-slider-wrap">
      {badge}
      <div className="card-media-slider">
        <Carousel {...settings}>{Slides}</Carousel>
      </div>
      {showOriginalImage && (
        <ViewOriginalImage
          originalSrc={currentImg !== null && currentImg.dataset.originalSrc}
        />
      )}
    </div>
  );
};

export default CardMediaSlider;
