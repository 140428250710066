import React from "react";
import { useQuery} from "@apollo/react-hooks";
import { useHistory, useParams } from 'react-router-dom';

import { GET_POST } from 'graphql/query/post-gql';
import { GET_ME } from "graphql/query/user-gql";

import { UpdateWrap, WrapColumn } from "../../wraps";
import { PostUploadMedia, PostUploadAttachments, PostUpdateDataForm, PostManageImages } from "../forms";
import { TopAppBar } from "../../menu-bar";
import PostRemoveButton from "../post-remove-button";
import {ElementVisibility, Localize} from "../../service";
import { ErrorResult, Loader } from "../../result";

import './post-update.css';


const PostUpdate = () => {

    const history = useHistory();
    const { postSlug } = useParams();

    const {loading, error, data, client} = useQuery(GET_POST, {
        variables: { slug : postSlug },
        // fetchPolicy: "cache-and-network",
        fetchPolicy:"network-only",
        pollInterval: 30000
    });

    const { me } = client.readQuery({query: GET_ME});

    if(loading) return (
        <div className="post-update">
            <Loader />
        </div>
    );

    if(error || data.post === null || me.id !== data.post.author.id){
        return (
            <ErrorResult
                error={error}
                goToLink={`/${me.nickname}/drafts`}
                goToText={ <Localize>GLOBAL.Link_Text_OnDraftError</Localize> }
            />
        );
    }

    const { id, slug, media, attachments } = data.post;

    return (
        <div className="post-update">
            <UpdateWrap>
                <ElementVisibility maxWidth={ 768 } type="toggle" comparison="<">
                    <WrapColumn position="show">
                        <TopAppBar
                            title={ <Localize wrap="h3">POST.Title_EditPost</Localize>  }
                            rightSide={
                                <PostRemoveButton
                                    postId={data.post.id}
                                    myNickname={ data.post.author.nickname }
                                    smallButton
                                />
                            }
                        />
                    </WrapColumn>
                    <WrapColumn position="hide">
                        <h2>
                            <Localize wrap>POST.Title_EditPost</Localize>
                        </h2>
                    </WrapColumn>
                </ElementVisibility>

                <PostManageImages
                    postId={ id }
                    postSlug={ slug }
                    defaultMedia={ media }
                    loadingQuery={ loading }
                />

                <PostUploadMedia
                    postId={id}
                    postSlug={ slug }
                    defaultMedia={ media }
                />
                <PostUploadAttachments
                    postId={id}
                    postSlug={ slug }
                    defaultAttachments={ attachments }
                />
            </UpdateWrap>
            <UpdateWrap>
                <PostUpdateDataForm
                    post={ data.post }
                    history={ history }
                />
            </UpdateWrap>
        </div>
    );
};

export default PostUpdate;