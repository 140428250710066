import React from "react";
import { Link } from "react-router-dom";

import PostCardMenuFollow from "./post-card-menu-follow";
import PostCardMenuCopyLink from "./post-card-menu-copy-link";
import PostCardMenuDelete from "./post-card-menu-delete";
import { Localize } from "components/service";


const PostCardMenu = (props) => {

    const {
        hidePopup,
        me,
        post,
        itsMe = me.id === post.author.id,
        //role = me.role
    } = props;

    return (
        <ul className="modal-actions-list company-link-hover">
            {!itsMe &&
                <PostCardMenuFollow { ...props } />
            }
            <li>
                <PostCardMenuCopyLink link={`/post/${post.order_slug.replace(/[=]/g, '')}`} />
            </li>
            { itsMe &&
                <li>
                    <Link to={`/post/edit/${post.slug}`}>
                        <Localize>MENUS.Label_EditPost</Localize>
                    </Link>
                </li>
            }
            { itsMe &&
                <PostCardMenuDelete { ...props } />
            }
            <li>
                <button onClick={ hidePopup }>
                    <Localize>MENUS.Button_Text_Cancel</Localize>
                </button>
            </li>
        </ul>
    );
};

export default PostCardMenu;