import React from 'react';
import { Icon } from 'antd';

const NewsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M17.9,10.4l-1.5-6.7c-0.1-0.5-0.5-0.8-0.9-0.9c-0.5-0.1-0.9,0.1-1.2,0.5c-0.9,1.3-3.5,4.5-6.9,5.3L1.7,9.9
		c-0.9,0.2-1.4,1.1-1.2,2l1.1,4.9c0.2,0.9,1.1,1.4,2,1.2l0.9-0.2L6,20.7c0.2,0.5,0.6,0.8,1.1,1c0.2,0.1,0.5,0.1,0.7,0.1
		c0.3,0,0.5-0.1,0.8-0.2c1-0.4,1.4-1.6,1-2.6l-1.2-2.2l0.8-0.2c3.5-0.8,7.2,0.9,8.6,1.7c0.4,0.2,0.9,0.2,1.3-0.1
		c0.4-0.3,0.5-0.8,0.4-1.2L17.9,10.4z M3.3,16.5c-0.1,0-0.2,0-0.2-0.1l-1.1-4.9c0-0.1,0-0.2,0.1-0.2L7,10.2l1.3,5.1l-1,0.2
		c0,0,0,0,0,0l-2,0.5l0,0l-0.6,0.1l-0.3,0.1c0,0,0,0,0,0L3.3,16.5z M8,20.3c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.3-0.3L6,17.4
		l0.9-0.2l1.3,2.5C8.3,19.9,8.2,20.2,8,20.3z M9.7,15L8.4,9.8c3.1-1,5.3-3.4,6.6-5.1l1.4,6l1.5,6C16,15.7,12.9,14.6,9.7,15z"/>
        <path d="M19.5,9.2c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.3,0.4,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0l2.4-0.6c0.4-0.1,0.7-0.5,0.6-0.9
		c-0.1-0.4-0.5-0.7-0.9-0.6L19.5,9.2z"/>
        <path d="M19.1,8.1c0.1,0,0.3,0,0.4-0.1l2.1-1.3c0.3-0.2,0.5-0.7,0.2-1c-0.2-0.3-0.7-0.5-1-0.2l-2.1,1.3c-0.3,0.2-0.5,0.7-0.2,1
		C18.6,8,18.8,8.1,19.1,8.1z"/>
        <path d="M22.9,12.1l-2.5-0.3c-0.4,0-0.8,0.3-0.8,0.7s0.3,0.8,0.7,0.8l2.5,0.3c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.7
		C23.6,12.6,23.3,12.2,22.9,12.1z"/>
    </svg>
);

const PlatformNewsSvg = () => (
    <svg fill="currentColor"  width="3.613em" height="1em" viewBox="0 0 108.4 30">
        <g>
            <path className="st0" d="M26.7,14.1c0-1.4-1.2-2.6-2.6-2.6l0,0H4.4v10.3h5.1v-7.6c0-0.9,0.9-0.9,0.9-0.9h1.4c0.9,0,0.9,0.9,0.9,0.9
			v7.6H18v-7.6c0-0.9,0.9-0.9,0.9-0.9h1.4c0.9,0,0.9,0.9,0.9,0.9v7.6H23h0.9h2.6L26.7,14.1z"/>
            <path className="st0" d="M36.9,19.1c0,0.9-0.9,0.9-0.9,0.9h-1.3c-0.9,0-0.9-0.9-0.9-0.9v-4.9c0-0.9,0.9-0.9,0.9-0.9H36
			c0.9,0,0.9,0.9,0.9,0.9V19.1z M42.2,14.1c0-1.4-1-2.6-2.6-2.6l0,0H28.5v9.2v1.1V27h5.1v-5.1h6l0,0c1.4,0,2.4-1.2,2.6-2.5V14.1z"/>
        </g>
        <g>
            <path className="st0" d="M45.5,21.5V6h5.3l3.7,12.7h0.2V6h3.1v15.4h-5.1L48.8,8.8h-0.2v12.7H45.5z"/>
            <path className="st0" d="M61.1,21.5V6h9.9v2.7h-6.8v3.6h5.5V15h-5.5v3.7h6.8v2.7H61.1z"/>
            <path className="st0"
                  d="M72.4,6h3.3l1.9,12.7h0.3L80.7,6h3.6L87,18.8h0.3L89.3,6h3.3l-2.9,15.4h-4.7L82.5,9.7L80,21.5h-4.7L72.4,6z"
            />
            <path className="st0" d="M99.3,8.5c-1.6,0-2.5,0.5-2.5,1.6c0,0.5,0.2,0.9,0.6,1.1c0.4,0.3,1.4,0.6,2.9,1.1c1.5,0.5,2.5,1,3.1,1.6
			c0.6,0.6,0.9,1.6,0.9,2.8c0,1.6-0.5,2.8-1.5,3.7s-2.3,1.2-3.8,1.2c-1.2,0-2.6-0.2-4.3-0.5L94,21l0.3-2.4c2,0.3,3.6,0.4,4.6,0.4
			c1.6,0,2.4-0.7,2.4-2c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-1.1-0.6-2.2-0.9c-1.7-0.5-3-1.1-3.7-1.8c-0.7-0.7-1.1-1.6-1.1-2.9
			c0-1.5,0.5-2.7,1.4-3.4c0.9-0.8,2.2-1.1,3.8-1.1c1.1,0,2.5,0.2,4.2,0.5l0.8,0.2l-0.2,2.5C101.7,8.6,100.2,8.5,99.3,8.5z"/>
        </g>
    </svg>
);


const NewsIcon = props => {

    if(props.type === "platform"){
        return <Icon component={ PlatformNewsSvg }/>;
    }

    return <Icon component={ NewsSvg }/>;
};


export default NewsIcon;