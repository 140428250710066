import React from "react";
import { Icon } from "antd";

const PlayVideoArrowSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M3.8,12.1V3.6c0-1.4,1.5-2.3,2.7-1.6l7.3,4.2l7.3,4.2c1.2,0.7,1.2,2.4,0,3.1l-7.3,4.2l-7.3,4.2c-1.2,0.7-2.7-0.2-2.7-1.6 V12.1z" />
  </svg>
);

const PlayVideoArrowIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={PlayVideoArrowSvg} />;
};

export default PlayVideoArrowIcon;
