import React from "react";
import { Link } from "react-router-dom";

import AvatarBlock from "components/avatar";
import { FollowButton } from "components/follow";


const UserList = ({ users, me }) => {

    return (
        <div className="users-list">
            { users.map( ({ node }) => {
                    const {
                        id,
                        avatar,
                        nickname,
                        name,
                        initials,
                        status,
                    } = node;

                    return (
                        <div className="user-row border-color-light" key={id}>
                            <Link to={`/${nickname}`} className="card-data">
                                <AvatarBlock
                                    size={ 44 }
                                    photoUrl={ avatar }
                                    initials={ initials }
                                    label={`@${nickname}`}
                                    blocked={ status === "block"  ? "normal" : "" }
                                    text={ status === "block"  ? "Inactive user" : name }
                                />
                            </Link>
                            <div className="card-follow">
                                { id !== me.id ?
                                    <FollowButton author={ node }/> :
                                    <b className="site-label-text-color">It's you</b>
                                }
                            </div>
                        </div>
                    );
                })

            }
        </div>
    );
};

export default UserList;