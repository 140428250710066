import React from 'react';

import {AuthorizationRoutes} from "../../routes";

import './authorization-page.css';


const AuthorizationPage = () => {
    return (
        <main className="authorization-page">
            <AuthorizationRoutes />
        </main>
    );
};

export default AuthorizationPage;