import {gql} from "apollo-boost";


export const FOLLOW_USER = gql`
    mutation FollowUser($user_id : ID!) {
        followUser(user_id : $user_id){
            label
            message
            me_following
        }
    }`;

export const LIKE = gql`
    mutation Like($post_id : ID!) {
        like(post_id : $post_id){
            label
            message
            liked
            like_count
        }
    }`;

export const LIKE_COMMENT = gql`
    mutation LikeComment($comment_id : ID!) {
        commentLikeDislike(comment_id : $comment_id){
            label
            message
            liked
            like_count
        }
    }`;

export const FOLLOW_USER_WITH_COUNTERS = gql`
    mutation FollowUser($user_id : ID!) {
        followUser(user_id : $user_id){
            label
            message
            me_following
            counters : user_counters{
               published : public_posts
               followers
               following
            }
        }
    }`;

export const FOLLOW_COLLECTION = gql`
    mutation FollowCollection($collection_id: ID!) {
        followCollection(collection_id: $collection_id){
            label
            message 
            collection{
                id
                slug
                me_following
            }
        }
    }`;

export const FOLLOW_HASHTAG = gql`
  mutation FollowHashtag($hashtag_id: ID!) {
      followHashtag(hashtag_id: $hashtag_id){
        label
        message 
        hashtag{
            id
            slug
            me_following
        }
      }
    }`;
