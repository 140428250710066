import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { GET_MY_DASHBOARD } from "graphql/query/user-gql";

import { CardList } from "../../card";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, Loader } from "../../result";
import Icons from "../../icons";


const HomeTimelinePosts = () => {

    const { loading, data, fetchMore } = useQuery(GET_MY_DASHBOARD, {
        variables: {
            status : 'public'
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        myDashboard : {
            edges : posts,
            __typename : typename,
            pageInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {
        if(pageInfo.currentPage < pageInfo.lastPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.myDashboard.edges;
                    const pageInfo = fetchMoreResult.myDashboard.pageInfo;

                    const newPosts = {
                        myDashboard: {
                            edges : [
                                ...posts,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newPosts : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="home-timeline-posts">
            { !loading && pageInfo.total < 1 &&
                <div className="home-timeline-message">
                    <div className="no-content-icon site-icon-color-second">
                        <Icons.NoContent type="posts" />
                    </div>
                    <Localize wrap="p">NO_DATA.Text_Timeline</Localize>
                    <p>
                        <Link to="/post/create">
                            <Localize>NO_DATA.Link_Text_TimelineCreatePost</Localize>
                        </Link>
                    </p>
                    <p>
                        <Link to="/search">
                            <Localize>NO_DATA.Link_Text_TimelineSearch</Localize>
                        </Link>
                    </p>
                </div>
            }
            { posts !== undefined && posts.length > 0 &&
                <>
                    <CardList cardItems={posts} type="homeTimeline" />
                    {
                        pageInfo.currentPage < pageInfo.lastPage &&
                            <LoadMoreOnScroll
                                fetchAction={() => fetchMoreAction()}
                                loading={loading}
                            />
                    }
                </>
            }
            { loading && posts === undefined && <Loader /> }
        </div>
    );

};

export default HomeTimelinePosts;