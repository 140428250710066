import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form } from "antd";

import { CHANGE_FORGOT_PASSWORD } from "graphql/mutation/user-gql";
import { CHECK_FORGOT_PASSWORD } from "graphql/query/user-gql";

import NormalChangeForgotPasswordForm from "./change-forgot-password-form";
import { errorNotification, Loader, successNotification } from "components/result";
import { Localize } from "components/service";


import "./change-forgot-password-form.css";


const ChangeForgotPasswordFormContainer = (props) => {

    const { forgotToken } = useParams();
    const history = useHistory();
    const { logo : Logo } = props;

    const { data, error} = useQuery(CHECK_FORGOT_PASSWORD, {
        variables: {
            forgotToken,
        },
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true
    });

    const [changeForgotRequest, { loading : sendFormLoading}] = useMutation( CHANGE_FORGOT_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    updeteForgotPassword : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
                history.push("/");
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <div className="form-holder">
            <Logo />
            <h2>
                <Localize>FORMS.Title_ChangePassword</Localize>
            </h2>
            { data === undefined ?
                <div className="change-forgot-message">
                    {error ?
                        <>
                            <p className="error-text">{error.graphQLErrors[0].extensions.reason}</p>
                            <div className="sign-link-wrap">
                                <span className="site-label-text-color">
                                    <Localize>FORMS.Text_ForgotPassword_Question</Localize>
                                </span>
                                <Link to="/forgot-password" className="company-color">
                                    <Localize>FORMS.Link_Text_ForgotPassword2</Localize>
                                </Link>
                            </div>
                        </> :
                        <>
                            <p>
                                <Localize>FORMS.Loading_Text_ChangePassword</Localize>
                            </p>
                            <Loader />
                        </>
                    }
                </div> :
                <>
                    <NormalChangeForgotPasswordForm
                        {...props}
                        changeForgotRequest={changeForgotRequest}
                        sendFormLoading={sendFormLoading}
                        userId={ data.checkUpdateForgottenPassword.user.id }
                        forgotToken={ forgotToken }
                    />
                    <div className="sign-link-wrap">
                        <span className="site-label-text-color">
                            <Localize>FORMS.Text_ChangePassword_Question</Localize>
                        </span>
                        <Link to="/" className="company-color">
                            <Localize>FORMS.Link_Text_Login</Localize>
                        </Link>
                    </div>
                </>
            }
        </div>
    );
};

const ChangeForgotPasswordForm = Form.create({ name: 'change-forgot-password' })(ChangeForgotPasswordFormContainer);

export default ChangeForgotPasswordForm;