import React from "react";
import { Switch, Route } from 'react-router-dom';

import {
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
    ChangeForgotPasswordForm
} from "../user/forms";

import logo from '../../assets/mobile_logo.svg';


const Logo = () => {
    return(
        <div className="logo-img">
            <img src={logo} alt="logo" />
        </div>
    );
};

const AuthorizationRoutes = () => {
    return(
        <Switch>
            <Route path="/registration/" render={() => <RegistrationForm logo={ Logo } />} />} exact />
            <Route path="/forgot-password/:forgotToken" render={() => <ChangeForgotPasswordForm logo={ Logo } />} />} exact />
            <Route path="/forgot-password/" render={() => <ForgotPasswordForm logo={ Logo } />} />} exact />
            <Route render={() => <LoginForm logo={ Logo } />} />
        </Switch>
    );
};

export default AuthorizationRoutes;