import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { saveAs } from "file-saver";
import { Button } from "antd";

import { EXPORT_USERS } from 'graphql/mutation/user-gql';

import { errorNotification } from "components/result";
import { Localize } from "components/service";
import Icons from "../../icons";


const UserExportButton = () => {

    const [ usersExportPdf, { loading } ] = useMutation( EXPORT_USERS,
        {
            update(cache, { data }) {

                const { file } = data.usersExportPdf;

                saveAs(file, "MP_Contacts.pdf");

            }
        });

    return(
        <Button
            className="rounded-button download-button"
            onClick={ () => {
                if (!loading){
                    usersExportPdf().catch((error) => {
                        errorNotification(error);
                    });
                }
            }}
        >
            <Icons.Download loading={ loading } />
            <Localize>SEARCH.Button_Label_Contacts</Localize>
        </Button>
    )
}

export default UserExportButton;