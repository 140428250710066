import React, { useState } from "react";
import { Link } from "react-router-dom";
import {Button, Modal} from "antd";

import { useLogout } from "components/use-hooks";
import Icons from "components/icons";
import { Localize } from "components/service";


const HeaderActionsList = ({ hideModal }) => {

    const { logout } = useLogout();

    return(
        <ul className="modal-actions-list company-link-hover">
            <li>
                <Link
                    to="/profile/settings"
                    onClick={ hideModal }
                >
                    <Localize>MENUS.Label_EditProfile</Localize>
                </Link>
            </li>
            <li>
                <Link
                    to="/profile/additional-information"
                    onClick={ hideModal }
                >
                    <Localize>MENUS.Label_AdditionalInfo</Localize>
                </Link>
            </li>
            <li>
                <Link
                    to="/profile/change-password"
                    onClick={ hideModal }
                >
                    <Localize>MENUS.Label_ChangePassword</Localize>
                </Link>
            </li>
            <li>
                <button
                    className="logoutBut"
                    onClick={ () => logout({ redirectRoute: false }) }
                >
                    <Localize wrap>MENUS.Button_Text_Logout</Localize>
                </button>
            </li>
            <li>
                <button onClick={ hideModal }>
                    <Localize wrap>MENUS.Button_Text_Cancel</Localize>
                </button>
            </li>
        </ul>
    );
};


const UserHeaderPopup = () => {

    const [ showModal, setShowModal ] = useState(false);

    return (
        <>
            <Button
                className="user-header-small-but site-icon"
                shape="circle"
                onClick={ () => setShowModal(true) }
            >
                <Icons.Settings />
            </Button>
            <Modal
              title={ null }
              footer={ null }
              visible={ showModal }
              closable={false}
              onCancel={ () => setShowModal(false) }
              wrapClassName="modal-actions"
              centered
            >
                <HeaderActionsList
                    hideModal={ () => setShowModal(false)  }
                />
            </Modal>
        </>
    );
};

export default UserHeaderPopup;