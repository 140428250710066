import { gql } from "apollo-boost";

import { pageInfoFieldsFull } from "./pagination-frgm";
import { userFieldsShort } from "./user-frgm";


export const commentsFields = gql`
    fragment commentsFields on Comment {
        id
        content
        created_at
        like_count
        liked
        author{
            ...userFieldsShort
        }      
    }
    ${ userFieldsShort }
`;

export const commentsFieldsFull = gql`
    fragment commentsFieldsFull on CommentConnection {
        pageInfo{
          ...pageInfoFieldsFull
        }
        edges {
            node{
                ...commentsFields
            }
        }
    }
    ${ commentsFields }
    ${ pageInfoFieldsFull }
`;

export const commentsFragment = gql`
    fragment commentsFragment on Post {
        comments(first : 15){
            ...commentsFieldsFull
        }
    }
    ${ commentsFieldsFull }
`;

export const commentsFragmentPaginated = gql`
    fragment commentsFragmentPaginated on Post {
        comments(first : 15, after: $commentsCursor){
            ...commentsFieldsFull
        }
    }
    ${ commentsFieldsFull }
`;