import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_AUTH_TOKEN } from "graphql/query/user-gql";

import { Authorization } from '../pages';


const Authentication = ({children}) => {

    const { data } = useQuery(GET_AUTH_TOKEN);

    const authToken  = (data !== undefined &&
                        data.authToken !== undefined &&
                        data.authToken !== false) ? data.authToken : false;

    return (
        <>
            { authToken ? children : <Authorization /> }
        </>
    );
};


export default Authentication;
