import React from 'react';
import { useRouteMatch } from "react-router-dom";

import { ContainerWrap } from "../../wraps";
import SearchBlock from "../../search";

const SearchPage = () => {

    let match = useRouteMatch("/search/:searchType");

    return (
        <ContainerWrap className="container-margin container-margin-short-top">
            <SearchBlock
                searchType={ match ?  match.params.searchType : "post" }
            />
        </ContainerWrap>
    );
};

export default SearchPage;