import React, {useState} from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { SEARCH_USERS } from 'graphql/query/search-gql';
import { GET_ME } from "graphql/query/user-gql";

import { UserList, UsersGrid, UserExportButton } from "components/user";
import Icons from "components/icons";
import { LoadMoreOnScroll, Localize } from "components/service";
import { errorNotification, Loader } from "components/result";
import { getDate } from "utils";


const whereTypes = {
    "Date" : {
        column: "CREATED_AT",
        operator: "GTE",
        value : getDate.past(90, getDate.now('start'))
    }
}

const SearchUsers = (props) => {

    const { query, onlyQuery } = props;
    const [ filterType, setFilterType ] = useState( 'All' );

    const { loading, data, fetchMore, client } = useQuery(SEARCH_USERS, {
        variables: {
            text : query,
            where : filterType === "All" ? null : { ...whereTypes[filterType] }
        },
        fetchPolicy:"cache-first",
        notifyOnNetworkStatusChange: true
    });

    const [ usersView, setUsersView ] = useState(query === "" ? "grid" : "row");
    const { me } = client.readQuery({query: GET_ME});

    const {
        searchUsers : {
            pageInfo,
            edges : users
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchUsers = fetchMoreResult.searchUsers;

                    const newSearchResults = {
                        searchUsers: {
                            edges : [
                                ...users,
                                ...searchUsers.edges
                            ],
                            __typename: searchUsers.__typename,
                            pageInfo : searchUsers.pageInfo
                        }
                    };

                    return searchUsers.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    if(onlyQuery){
        return {loading, data, fetchMoreAction};
    }

    return(
        <>
            <div className="search-sort-and-view border-color-light">
                <div className="search-sort">
                    <Radio.Group
                        onChange={
                            (e) => { setFilterType(e.target.value) }
                        }
                        defaultValue={ filterType }
                    >
                        <Radio.Button value="All">
                            <Localize>FILTER.All_Users</Localize>
                        </Radio.Button>
                        <Radio.Button value="Date">
                            <Localize>FILTER.New_Users</Localize>
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <div className="right-group">
                    <div className="export-users">
                        <UserExportButton />
                    </div>
                    <div className="posts-view-type">
                        <Radio.Group
                            onChange={ (e) => { setUsersView(e.target.value) }}
                            defaultValue={ usersView }
                            className="input-bg-color"
                        >
                            <Radio.Button value="grid"><Icons.View type="grid" /></Radio.Button>
                            <Radio.Button value="row"><Icons.View type="list" /></Radio.Button>
                        </Radio.Group>
                    </div>
                </div>

            </div>
            { data !== undefined && pageInfo.total > 0 &&
                <>
                    { usersView === "grid"  ?
                        <UsersGrid
                            users={ users }
                            me={ me }
                        />:
                        <UserList
                            users={ users }
                            me={ me }
                        />
                    }

                    { pageInfo.hasNextPage &&
                        <LoadMoreOnScroll
                            fetchAction={() => fetchMoreAction()}
                            loading={ false }
                        />
                    }
                </>
            }
            { !loading && data !== undefined && pageInfo.total === 0  &&
                <div className="search-result-not-found">
                    <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search_Users</Localize>
                </div>
            }
            { loading && <Loader /> }
        </>
    );
};

export default SearchUsers;