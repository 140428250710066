import React from "react";
import { useHistory } from "react-router-dom";
import { Input, Radio } from "antd";

import Icons from "../../icons";
import { Localize } from "components/service";


const SearchForm = (props) => {

    const {
        setSearchVariables,
        searchVariables,
        fixedType = false,
        placeholder = "SEARCH.Input_Placeholder"
    } = props;

    const history = useHistory();

    return (
        <div className="search-form">
            <div className="search-field">
                <Input
                    name="search"
                    placeholder={ Localize({ children: placeholder }).props.children }
                    className="input-bg-color"
                    prefix={
                        <Icons.Search />
                    }
                    onChange={ ({ target: { value }}) => setSearchVariables(
                        {
                            ...searchVariables,
                            input: value
                        }
                    )}
                    value={ searchVariables.input }
                />
                { searchVariables.input.length > 0 &&
                    <button
                        className="clear-search site-title-color ant-btn"
                        onClick={ () => setSearchVariables({ ...searchVariables, input: ''}) }
                    >
                        <Localize>GLOBAL.Button_Text_Cancel</Localize>
                    </button>
                }
            </div>
            { !fixedType &&
                <div className="search-type-wrap">
                    <Radio.Group
                        onChange={ ({ target: { value }}) => {
                            history.push(`/search/${ value }`);
                        }}
                        value = { searchVariables.type }
                    >
                        <Radio.Button value="news">
                            <Icons.News />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_News</Localize>
                            </span>
                        </Radio.Button>
                        <Radio.Button value="post">
                            <Icons.Post />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_Posts</Localize>
                            </span>
                        </Radio.Button>
                        <Radio.Button value="user">
                            <Icons.User />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_Users</Localize>
                            </span>
                        </Radio.Button>
                        <Radio.Button value="hashtag">
                            <Icons.Hashtag />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_Hashtags</Localize>
                            </span>
                        </Radio.Button>
                        <Radio.Button value="collection">
                            <Icons.Collection />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_Collections</Localize>
                            </span>
                        </Radio.Button>
                        <Radio.Button value="document">
                            <Icons.Document />
                            <span className="label">
                                <Localize>SEARCH.Type_Label_Documents</Localize>
                            </span>
                        </Radio.Button>
                    </Radio.Group>
                </div>
            }
        </div>
    );
};

export default SearchForm;