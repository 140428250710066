import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Select } from "antd";

import { USER_UPDATE_META } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/result";
import { Localize } from "components/service";
import Icons from "components/icons";
import { userNormalMeta } from "utils"


const formSubmit = ({ event, form, updateUserMeta }) => {
    event.preventDefault();

    let inputValues = [];

    for (let [key, meta_value] of Object.entries(form.getFieldsValue())) {

        let [meta_key, meta_type, id] = key.split('-');

        if(!!meta_value){
            inputValues.push({
                id,
                meta_key,
                meta_type,
                meta_value
            });
        }
    }

    form.validateFields((err) => {
        if (!err) {

            updateUserMeta({
                variables : {
                    input: [...inputValues]
                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    });
};

const NormalAdditionalInformationForm = (props) => {

    const {
        metaFields,
        form,
        history,
        requestLoading,
        updateUserMeta,
        nickname
    } = props;

    const {getFieldDecorator} = form;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const {
        jobDescription = {},
        companyDepartment = {},
        contactPhone = {},
        contactEmail = {},
        workingOn = {}
    } = metaFields;

    const { Option } = Select;

    return (
        <Form
            className="profile-form"
            layout="horizontal"
            onSubmit={(event) => {
                formSubmit({event, form, updateUserMeta});
            }}
        >
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_ContactPhone</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                { getFieldDecorator(
                    `contactPhone-string${ !!contactPhone ? `-${contactPhone.id}` : "" }`,
                    {
                        rules: [
                            {
                                type: "string",
                                pattern: /^[\d\-+ ]+$/,
                                message: <Localize>FORM_RULES.ContactPhone</Localize>
                            },
                        ],
                        initialValue: !!contactPhone ? contactPhone.meta_value : ""
                    }
                )(
                    <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_ContactPhone" }).props.children } />
                )
                }
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_ContactEmail</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator(
                    `contactEmail-string${ !!contactEmail ? `-${ contactEmail.id }` : "" }`, {
                        rules: [
                            {
                                type: 'email',
                                message: <Localize>FORM_RULES.Email</Localize>,
                            }
                        ],
                        initialValue: !!contactEmail ? contactEmail.meta_value : ""
                    })(
                    <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_ContactEmail" }).props.children } />
                )}
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_Department</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator(
                    `companyDepartment-string${ !!companyDepartment ? `-${ companyDepartment.id }` : "" }`,
                    {
                        initialValue: !!companyDepartment ? companyDepartment.meta_value : undefined
                    }
                )(
                    <Select
                        placeholder={ Localize({children: "FORMS.Input_Placeholder_Department" }).props.children }
                        suffixIcon={ <Icons.ArrowDown /> }
                    >
                        <Option value="Management">
                            <Localize>FORMS.Select_OptionLabel_Management</Localize>
                        </Option>
                        <Option value="ITManagement">
                            <Localize>FORMS.Select_OptionLabel_ITManagement</Localize>
                        </Option>
                        <Option value="LegalCounsel">
                            <Localize>FORMS.Select_OptionLabel_LegalCounsel</Localize>
                        </Option>
                        <Option value="FinancialAccounting">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccounting</Localize>
                        </Option>
                        <Option value="MarketingAndSales">
                            <Localize>FORMS.Select_OptionLabel_MarketingAndSales</Localize>
                        </Option>
                        <Option value="SystemStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_SystemStandConstruction</Localize>
                        </Option>
                        <Option value="IndividualStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_IndividualStandConstruction</Localize>
                        </Option>
                        <Option value="WarehousingAndLogistics">
                            <Localize>FORMS.Select_OptionLabel_WarehousingAndLogistics</Localize>
                        </Option>
                        <Option value="ConstructionManagement">
                            <Localize>FORMS.Select_OptionLabel_ConstructionManagement</Localize>
                        </Option>
                        <Option value="AssemblyAndTransport">
                            <Localize>FORMS.Select_OptionLabel_AssemblyAndTransport</Localize>
                        </Option>
                        <Option value="Draft">
                            <Localize>FORMS.Select_OptionLabel_Draft</Localize>
                        </Option>
                        <Option value="CentralPurchasing">
                            <Localize>FORMS.Select_OptionLabel_CentralPurchasing</Localize>
                        </Option>
                        <Option value="Secretariat">
                            <Localize>FORMS.Select_OptionLabel_Secretariat</Localize>
                        </Option>
                        <Option value="Personnel">
                            <Localize>FORMS.Select_OptionLabel_Personnel</Localize>
                        </Option>
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_JobDescription</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator(
                    `jobDescription-string${ !!jobDescription ? `-${ jobDescription.id }` : "" }`,
                    {
                        initialValue: !!jobDescription ? jobDescription.meta_value : undefined
                    }
                )(
                    <Select
                        placeholder={ Localize({children: "FORMS.Input_Placeholder_JobDescription" }).props.children }
                        suffixIcon={ <Icons.ArrowDown /> }
                    >
                        <Option value="CEO">
                            <Localize>FORMS.Select_OptionLabel_CEO</Localize>
                        </Option>
                        <Option value="CTO">
                            <Localize>FORMS.Select_OptionLabel_CTO</Localize>
                        </Option>
                        <Option value="PM">
                            <Localize>FORMS.Select_OptionLabel_PM</Localize>
                        </Option>
                        <Option value="PMCalculator">
                            <Localize>FORMS.Select_OptionLabel_PMCalculator</Localize>
                        </Option>
                        <Option value="PMCADDraftsman">
                            <Localize>FORMS.Select_OptionLabel_PMCADDraftsman</Localize>
                        </Option>
                        <Option value="CADDraftsman">
                            <Localize>FORMS.Select_OptionLabel_CADDraftsman</Localize>
                        </Option>
                        <Option value="ProjectAssistant">
                            <Localize>FORMS.Select_OptionLabel_ProjectAssistant</Localize>
                        </Option>
                        <Option value="DevelopmentTeamLead">
                            <Localize>FORMS.Select_OptionLabel_DevelopmentTeamLead</Localize>
                        </Option>
                        <Option value="SeniorBackendDeveloper">
                            <Localize>FORMS.Select_OptionLabel_SeniorBackendDeveloper</Localize>
                        </Option>
                        <Option value="SoftwareDeveloper">
                            <Localize>FORMS.Select_OptionLabel_SoftwareDeveloper</Localize>
                        </Option>
                        <Option value="SystemAdministrator">
                            <Localize>FORMS.Select_OptionLabel_SystemAdministrator</Localize>
                        </Option>
                        <Option value="LogisticsEmployee">
                            <Localize>FORMS.Select_OptionLabel_LogisticsEmployee</Localize>
                        </Option>
                        <Option value="HeadSystemsStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_HeadSystemsStandConstruction</Localize>
                        </Option>
                        <Option value="HeadIndividualStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_HeadIndividualStandConstruction</Localize>
                        </Option>
                        <Option value="FinancialAccountantReceivable">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantReceivable</Localize>
                        </Option>
                        <Option value="FinancialAccountantPayable">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantPayable</Localize>
                        </Option>
                        <Option value="FinancialAccountantHR">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantHR</Localize>
                        </Option>
                        <Option value="SalesMarketing">
                            <Localize>FORMS.Select_OptionLabel_SalesMarketing</Localize>
                        </Option>
                        <Option value="Clerk">
                            <Localize>FORMS.Select_OptionLabel_Clerk</Localize>
                        </Option>
                        <Option value="Secretary">
                            <Localize>FORMS.Select_OptionLabel_Secretary</Localize>
                        </Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_WorkingOn</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator(`workingOn-string${ !!workingOn ? `-${ workingOn.id }` : "" }`, {
                    initialValue: !!workingOn ? workingOn.meta_value : "",
                })(
                    <Input.TextArea
                        maxLength={ 128 }
                        autoSize={{ minRows: 2, maxRows: 3 }}
                    />
                )}
            </Form.Item>

            <Form.Item wrapperCol={{span: 24}} >
                <div className="form-actions">
                    <Button
                        className="rounded-button"
                        onClick={
                            () => history.push(`/${nickname}`)
                        }
                    >
                        <Localize>GLOBAL.Button_Text_Cancel</Localize>
                    </Button>
                    <Button
                        loading={requestLoading}
                        className="rounded-button"
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize wrap>GLOBAL.Button_Text_Save</Localize>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const AdditionalInformationContainer = (props) => {

    const { me } = props;
    const history = useHistory();

    let metaFields = userNormalMeta(me.meta_fields);

    const [ updateUserMeta, { loading }] = useMutation(USER_UPDATE_META,
        {
            update(cache, { data }) {

                const {
                    userMetaSet:{
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    return (
        <NormalAdditionalInformationForm
            {...props}
            updateUserMeta={updateUserMeta}
            requestLoading={loading}
            metaFields={metaFields}
            nickname={ me.nickname }
            history={history}
        />
    );
};

const AdditionalInformationForm = Form.create({ name: 'additional_info' })(AdditionalInformationContainer);

export default AdditionalInformationForm;