const english = {
    'COLLECTION.Button_Create' : 'New collection',
    'COLLECTION.Modal_Title_Create' : 'Create collection',
    'COLLECTION.Title_EditCollection' : 'Edit Collection',
    'COLLECTION.Type_Private' : 'private',
    'COLLECTION.Type_Public' : 'public',
    'COLLECTION.Text_YourDefault' : 'Your default private collection',
    'COLLECTION.Link_Text_Edit' : 'Edit collection',
    'COLLECTION.Modal_Title_Remove' : 'Remove collection',
    'COLLECTION.Button_Delete' : 'Delete collection',
    'COLLECTION.Text_ModalDelete_Question' : 'Are you sure you want to delete this collection?',
    'COLLECTION.Title_Default' : 'Default collection',
    'COLLECTION.Text_PostCount' : '{ postCount } posts',
    'COLLECTION.Text_YourCollection' : 'Your collection',
    'COLLECTION.Modal_Title_AddPost' : 'Add to collection',
    'FOLLOW.Button_Text_Follow' : 'Follow',
    'FOLLOW.Button_Text_Unfollow' : 'Unfollow',
    'FOLLOW.Button_Text_Following' : 'Following',
    'FOLLOW.Button_Text_FollowCollection' : 'Follow Collection',
    'FOLLOW.Button_Text_UnfollowCollection' : 'Unfollow Collection',
    'FOLLOW.Button_Text_FollowHashtag' : 'Follow Hashtag',
    'FOLLOW.Button_Text_UnfollowHashtag' : 'Unfollow Hashtag',
    'FOLLOW.Type_Label_Collections' : '{ icon } Collections',
    'FOLLOW.Type_Label_Users' : '{ icon } Users',
    'FOLLOW.Type_Label_Hashtags' : '{ icon } Hashtags',
    'FORM_RULES.Required_Username' : 'Please input your Username!',
    'FORM_RULES.Required_Password' : 'Please input your Password!',
    'FORM_RULES.Email' : 'The input is not valid E-mail',
    'FORM_RULES.Required_Email' : 'Please input your Email',
    'FORM_RULES.Min_FullName' : 'Full name must be at least { minLength } characters',
    'FORM_RULES.Required_FullName' : 'Please input your Full name',
    'FORM_RULES.Min_Username' : 'Username must be at least { minLength } characters',
    'FORM_RULES.RegExp_Username' : 'May contain only small letters, "-", "_", "."',
    'FORM_RULES.Min_Password' : 'Password must be at least { minLength } characters',
    'FORM_RULES.Required_NewPassword' : 'Please input new Password',
    'FORM_RULES.Required_Gender' : 'Please select your gender',
    'FORM_RULES.Required_Language' : 'Please select language',
    'FORM_RULES.ContactPhone' : 'May contain only digits, "+", "-", " "',
    'FORM_RULES.Required_CurrentPassword' : 'Please input your current password',
    'FORM_RULES.Required_ConfirmPassword' : 'Please confirm Password',
    'FORM_RULES.Required_PasswordInconsistent' : 'Two passwords that you enter is inconsistent!',
    'FORM_RULES.Text_UploadImage' : 'Upload high-quality image files less than { size } each',
    'FORM_RULES.Text_UploadVideo': 'Upload high-quality video files less than { size } each',
    'FORM_RULES.Text_UploadVideoLongerThan': 'Upload your HD video clip, no longer than { size } sec. long, here.',
    'FORM_RULES.Text_UploadVideoSpeed': 'Depending on the transfer speed, the data transfer can take up to several minutes.',
    'FORM_RULES.Attachment_Size' : 'Upload any files less than { size } Mb each',
    'FORM_RULES.Required_CollectionSlug' : 'Please input collection slug',
    'FORM_RULES.Min_CollectionSlug' : 'Slug must be at least  { minLength } characters',
    'FORM_RULES.RegExp_CollectionSlug' : 'May contain only small letters, numbers, "-", "_"',
    'FORMS.Title_Login' : 'Sign in',
    'FORMS.Input_Label_Username' : 'Username',
    'FORMS.Input_Label_Password' : 'Password',
    'FORMS.Button_Text_Login' : 'Sign in',
    'FORMS.Text_Registration_Question' : 'New user?',
    'FORMS.Link_Text_Registration' : 'Sign up',
    'FORMS.Link_Text_ForgotPassword' : 'Forgot password?',
    'FORMS.Link_Text_ForgotPassword2' : 'Send',
    'FORMS.Link_Text_Login' : 'Sign In',
    'FORMS.Title_Registration' : 'Sign up',
    'FORMS.Input_Label_Email' : 'Email',
    'FORMS.Input_Label_FullName' : 'Full name',
    'FORMS.Button_Text_Register' : 'Sign up',
    'FORMS.Text_Login_Question' : 'Have an account?',
    'FORMS.Button_Text_ForgotPassword' : 'Send Forgot Link',
    'FORMS.Title_ForgotPassword' : 'Forgot password',
    'FORMS.Success_Text_ForgotPassword' : 'We sent you a password recovery link in your email!',
    'FORMS.Input_Label_NewPassword' : 'New password',
    'FORMS.Title_ChangePassword' : 'Change password',
    'FORMS.Text_ChangePassword_Question' : 'Remember your password?',
    'FORMS.Text_ForgotPassword_Question' : 'Send new request?',
    'FORMS.Loading_Text_ChangePassword' : 'Please wait, we are check your token',
    'FORMS.Select_OptionLabel_Male' : 'Male',
    'FORMS.Select_OptionLabel_Female' : 'Female',
    'FORMS.Select_Label_Gender' : 'Gender',
    'FORMS.Select_Label_Language' : 'Language',
    'FORMS.Textarea_Label_About' : 'About',
    'FORMS.Input_Placeholder_FullName' : 'Enter your Full name',
    'FORMS.Input_Placeholder_Username' : 'Enter your Username',
    'FORMS.Input_Placeholder_Email' : 'Enter your E-Mail',
    'FORMS.Input_Placeholder_Gender' : 'Select your gender',
    'FORMS.Input_Placeholder_Language' : 'Select your Language',
    'FORMS.Input_Placeholder_About' : 'Write something about you',
    'FORMS.Input_Placeholder_ContactEmail' : 'Enter your contact Email',
    'FORMS.Input_Placeholder_ContactPhone' : 'Enter your contact phone',
    'FORMS.Input_Label_ContactPhone' : 'Contact phone',
    'FORMS.Input_Label_ContactEmail' : 'Contact E-Mail',
    'FORMS.Button_Text_ChangePassword' : 'Change password',
    'FORMS.Input_Placeholder_CurrentPassword' : 'Enter your current password',
    'FORMS.Input_Placeholder_NewPassword' : 'Enter new password',
    'FORMS.Input_Placeholder_ConfirmPassword' : 'Repeat new password',
    'FORMS.Input_Label_CurrentPassword' : 'Old Password',
    'FORMS.Input_Label_ConfirmPassword' : 'Type it again',
    'FORMS.Input_Label_Title' : 'Title',
    'FORMS.Input_Label_Description' : 'Description',
    'FORMS.Input_Label_CollectionName' : 'Collection name',
    'FORMS.Upload_Label_Cover' : 'Cover',
    'FORMS.Input_Label_CollectionUniqueName' : 'Collection unique name',
    'FORMS.Checkbox_Label_CollectionPublic' : 'Make this collection public',
    'FORMS.Input_Placeholder_Slug' : 'Unique name for URL',
    'FORMS.Select_OptionLabel_Management' : "Management",
    'FORMS.Select_OptionLabel_ITManagement' : "IT Management",
    'FORMS.Select_OptionLabel_FinancialAccounting' : "Financial Accounting",
    'FORMS.Select_OptionLabel_LegalCounsel' : "Legal Counsel",
    'FORMS.Select_OptionLabel_MarketingAndSales' : "Marketing and Sales",
    'FORMS.Select_OptionLabel_SystemStandConstruction' : "System Stand Construction",
    'FORMS.Select_OptionLabel_IndividualStandConstruction' : "Individual Stand Construction",
    'FORMS.Select_OptionLabel_WarehousingAndLogistics' : "Warehouse and Logistics",
    'FORMS.Select_OptionLabel_ConstructionManagement' : "Technical management",
    'FORMS.Select_OptionLabel_AssemblyAndTransport' : "Assembly and transport",
    'FORMS.Select_OptionLabel_Draft' : "Design",
    'FORMS.Select_OptionLabel_CentralPurchasing' : "Central purchasing",
    'FORMS.Select_OptionLabel_Secretariat' : "Secretariat",
    'FORMS.Select_OptionLabel_Personnel' : "Personnel",
    'FORMS.Input_Label_Department' : 'Department',
    'FORMS.Input_Label_JobDescription' : 'Job description',
    'FORMS.Input_Label_WorkingOn' : 'Working on',
    'FORMS.Input_Placeholder_Department' : 'Choose your Department',
    'FORMS.Input_Placeholder_JobDescription' : 'Choose your Job description',
    'FORMS.Select_OptionLabel_CEO' : "CEO",
    'FORMS.Select_OptionLabel_CTO' : "CTO",
    'FORMS.Select_OptionLabel_PM' : "Project Manager",
    'FORMS.Select_OptionLabel_PMCalculator' : "Project Manager/Calculator",
    'FORMS.Select_OptionLabel_PMCADDraftsman' : "Project manager/ CAD draftsman",
    'FORMS.Select_OptionLabel_CADDraftsman' : "CAD draftsman",
    'FORMS.Select_OptionLabel_ProjectAssistant' : "Project Assistant",
    'FORMS.Select_OptionLabel_DevelopmentTeamLead' : "Development team lead",
    'FORMS.Select_OptionLabel_SeniorBackendDeveloper' : "Senior backend developer",
    'FORMS.Select_OptionLabel_SoftwareDeveloper' : "Software developer",
    'FORMS.Select_OptionLabel_SystemAdministrator' : "System administrator",
    'FORMS.Select_OptionLabel_HeadSystemsStandConstruction'   : "Head of systems stand construction",
    'FORMS.Select_OptionLabel_HeadIndividualStandConstruction' : "Head of individual Stand construction",
    'FORMS.Select_OptionLabel_LogisticsEmployee' : "Logistics employee",
    'FORMS.Select_OptionLabel_FinancialAccountantReceivable' : "Financial accountant / Accounts receivable",
    'FORMS.Select_OptionLabel_FinancialAccountantPayable' : "Financial accountant / Accounts Payable",
    'FORMS.Select_OptionLabel_FinancialAccountantHR' : "Financial accountant / HR",
    'FORMS.Select_OptionLabel_SalesMarketing' : "Sales & Marketing",
    'FORMS.Select_OptionLabel_Clerk' : "Clerk",
    'FORMS.Select_OptionLabel_Secretary' : "Secretary",
    'GLOBAL.Button_Text_Confirm' : 'Confirm',
    'GLOBAL.Button_Text_Yes' : 'Yes',
    'GLOBAL.Button_Text_No' : 'No',
    'GLOBAL.Text_InactiveUser' : 'Inactive user',
    'GLOBAL.Button_Text_Cancel' : 'Cancel',
    'GLOBAL.Text_ItsYou' : 'It\'s you',
    'GLOBAL.Link_Text_EditProfile' : 'Edit profile',
    'GLOBAL.Title_EditProfile' : 'Edit Profile',
    'GLOBAL.Button_Text_ChangePhoto' : 'Change photo',
    'GLOBAL.Button_Text_Save' : 'Save',
    'GLOBAL.Title_AdditionalInfo' : 'Job Profile',
    'GLOBAL.Title_ChangePassword' : 'Change password',
    'GLOBAL.Button_Text_Next' : 'Next',
    'GLOBAL.Button_Text_Publish' : 'Publish',
    'GLOBAL.Button_Text_SaveDraft' : 'Save as draft',
    'GLOBAL.Button_Text_Done' : 'Done',
    'GLOBAL.Button_Text_Create' : 'Create',
    'GLOBAL.Button_Text_ChangeCover' : 'Change cover',
    'GLOBAL.Link_Text_OnError' : 'Go to timeline',
    'GLOBAL.Title_Followers' : '{ slug } followers',
    'GLOBAL.Title_Following' : '{ slug } following',
    'GLOBAL.Link_Text_OnDraftError' : 'Go to all drafts',
    'GLOBAL.Text_SystemError' : 'Sorry something went wrong. Please try to reload page or contact with administrator.',
    'GLOBAL.Link_Text_SystemError' : 'Back home',
    'GLOBAL.Text_Loading' : 'LOADING…',
    'GLOBAL.Button_Text_More' : 'more',
    'MENUS.Label_News' : 'News',
    'MENUS.Label_MyTimeline' : 'My Timeline',
    'MENUS.Label_Search' : 'Search',
    'MENUS.Label_Profile' : 'Profile',
    'MENUS.Label_NewPost' : 'New Post',
    'MENUS.Button_Text_CopyLink' : 'Copy link',
    'MENUS.Button_Text_Cancel' : 'Cancel',
    'MENUS.Label_EditPost' : 'Edit Post',
    'MENUS.Label_DeletePost' : 'Delete Post',
    'MENUS.Link_Text_GoCollection' : 'Go to Collection',
    'MENUS.Link_Text_GoHashtag' : 'Go to Hashtag',
    'MENUS.Label_Posts' : 'Posts',
    'MENUS.Label_DraftPosts' : 'Drafts',
    'MENUS.Label_Collections' : 'Collections',
    'MENUS.Label_Documents' : 'Documents',
    'MENUS.Label_Users' : 'Users',
    'MENUS.Label_Hashtags' : 'Hashtags',
    'MENUS.Label_Following' : 'Following',
    'MENUS.Label_Followers' : 'Followers',
    'MENUS.Label_EditProfile' : 'Edit profile',
    'MENUS.Label_AdditionalInfo' : 'Job profile',
    'MENUS.Label_ChangePassword' : 'Change password',
    'MENUS.Button_Text_Logout' : 'Logout',
    'MENUS.Label_Settings' : 'Settings',
    'MENUS.Label_EditCollection' : 'Edit Collection',
    'MENUS.Label_Notifications' : 'Notifications',
    'NO_DATA.Text_Timeline' : 'There are no posts in your timeline',
    'NO_DATA.Link_Text_TimelineCreatePost' : 'Create first post?',
    'NO_DATA.Link_Text_TimelineSearch' : 'Find something to follow?',
    'NO_DATA.Text_Likes' : 'Nobody likes this post.',
    'NO_DATA.Text_Search_Posts' : 'We could not find Posts{ br }according to your request.',
    'NO_DATA.Select_Chat' : 'Please select a chat{ br }to start messaging',
    'NO_DATA.Text_Search_Users' : 'We could not find Users{ br }according to your request.',
    'NO_DATA.Text_Search_Hashtags' : 'We could not find Hashtags{ br }according to your request.',
    'NO_DATA.Text_Search_Collections' : 'We could not find collections{ br }according to your request.',
    'NO_DATA.Text_Search_Documents' : 'We could not find documents{ br }according to your request.',
    'NO_DATA.Text_Notifications_All' : 'You have no notifications',
    'NO_DATA.Text_Notifications_Unread' : 'You have no unread notifications',
    'NO_DATA.Link_Text_UserCreatePost' : 'Create new post?',
    'NO_DATA.Text_UserPosts' : '{ who } have no Posts yet',
    'NO_DATA.Text_UserDraftPosts' : 'You have no draft posts',
    'NO_DATA.Text_UserCollections' : '{ who } have no Collections',
    'NO_DATA.Text_UserDocuments' : '{ who } have no Documents',
    'NO_DATA.Text_NewsTimeline' : 'There are no posts in the news',
    'NO_DATA.Text_Who_User' : 'User',
    'NO_DATA.Text_Who_You' : 'You',
    'NO_DATA.Text_UserFollowers' : '{ who } have no Followers',
    'NO_DATA.Text_HashtagFollowers' : 'This hashtag has no followers.',
    'NO_DATA.Text_CollectionFollowers' : 'This collection has no followers.',
    'NO_DATA.Text_HashtagFollow' : '{ who } don’t follow any hashtags',
    'NO_DATA.Text_CollectionFollow' : '{ who } don’t follow any collections',
    'NO_DATA.Text_UserFollow' : '{ who } don’t follow any users',
    'NO_DATA.Text_HashtagPosts' : 'There are no posts in this hashtag yet.',
    'NO_DATA.Text_CollectionPosts' : 'There are no posts in this collection yet.',
    'NO_DATA.Text_Search' : 'We could not find anything{ br }according to your request.',
    'NOTIFICATIONS.Drawer_Title' : 'Notifications',
    'NOTIFICATIONS.Filter_Label_All' : 'All',
    'NOTIFICATIONS.Filter_Label_Unread' : 'Unread',
    'POST.Text_ModalDelete_Question' : 'Are you sure you want to delete this post?',
    'POST.Button_Text_DeletePost' : 'Delete Post',
    'POST.Modal_Title_Delete' : 'Remove Post',
    'POST.Modal_Title_Share' : 'Share post',
    'POST.Button_Text_Share' : 'Share',
    'POST.Button_Text_Shared' : 'Shared',
    'POST.Button_Text_Like' : '{ likeCount } like',
    'POST.Button_Text_Likes' : '{ likeCount } likes',
    'POST.Modal_Title_Likes' : 'Likes',
    'POST.Button_Text_ViewComments' : 'View all { commentCount } comments',
    'POST.Drawer_Title_Comments' : 'Comments',
    'POST.Modal_Title_Attachments' : 'Added Files',
    'POST.Button_Text_Attachment' : '{ attachmentCount } document are added',
    'POST.Button_Text_Attachments' : '{ attachmentCount } documents are added',
    'POST.Input_Placeholder_Comments' : 'Add a comment…',
    'POST.Link_Text_ViewPost' : 'View Post',
    'POST.Link_Text_DownloadAttachment' : 'Download',
    'POST.Title_ChooseImage': 'Choose an image',
    'POST.Title_ChooseMedia' : 'Сhoose media',
    'POST.Text_AddImage' : 'Add image',
    'POST.Text_AddVideo' : 'Add video',
    'POST.Type_Post' : 'Post',
    'POST.Type_News' : 'News',
    'POST.Title_PostDetails' : 'Post Details',
    'POST.Title_CreatePost' : 'Create post',
    'POST.Title_EditPost' : 'Edit post',
    'POST.Drawer_Title_Attachments': 'Post documents',
    'POST.Drawer_Title_ManageImages' : 'Manage images',
    'POST.Title_AttachDocuments': 'Attach documents',
    'POST.Title_ManageImages' : 'Manage images',
    'POST.Title_ManageMedia' : 'Manage media',
    'POST.Dragger_Text_Attach' : 'Attach documents',
    'POST.Attachment_Count' : 'Files ({ attachmentCount })',
    'POST.Images_Count' : 'Images ({ imagesCount })',
    'POST.Media_Count' : 'Media ({ mediaCount })',
    'POST.Avatar_NewsTitle': 'NEWS',
    'POST.Slider_Text_VideoLoading' : 'Your video clip is currently streaming and will be available in 30 seconds!',
    'POST.Slider_Text_VideoFailed' : 'An error occurred while uploading the video to the server. Please try uploading your video again.',
    'SEARCH.Input_Placeholder_User' : 'Input name or username',
    'SEARCH.Input_Placeholder' : 'Start searching…',
    'SEARCH.Type_Label_News' : 'News',
    'SEARCH.Type_Label_Posts' : 'Posts',
    'SEARCH.Type_Label_Users' : 'Users',
    'SEARCH.Type_Label_Hashtags' : 'Hashtags',
    'SEARCH.Type_Label_Collections' : 'Collections',
    'SEARCH.Type_Label_Documents' : 'Documents',
    'SEARCH.Button_Label_Contacts' : 'Get contacts',
    'SORT.Label_Newest' : 'Newest',
    'SORT.Label_Popular' : 'Popular',
    'SORT.Label_MostUsed' : 'Most used',
    'SORT.Label_AtoZ' : 'A to Z',
    'FILTER.New_Users' : 'New colleagues',
    'FILTER.All_Users' : 'All',
    'SUMMARY_COUNTERS.Label_KNYS' : 'KNYS',
    'SUMMARY_COUNTERS.Label_Users' : 'Users',
    'SUMMARY_COUNTERS.Label_Posts' : 'Posts',
    'SUMMARY_COUNTERS.Label_Images' : 'Images',
    'SUMMARY_COUNTERS.Label_Videos' : 'Videos',
    'SUMMARY_COUNTERS.Label_Documents' : 'Documents',
    'SUMMARY_COUNTERS.Label_Comments' : 'Comments',
    'SUMMARY_COUNTERS.Label_Collections' : 'Collections',
    'SUMMARY_COUNTERS.Label_Teams' : 'Teams',
    'SUMMARY_COUNTERS.Label_Hashtags' : 'Hashtags',
    'MENUS.Label_MyProfile' : 'My Profile',
    'MENUS.Label_Competition' : 'COMPETITION',
    'MENUS.Label_Ranking' : 'RANKING',
    'GLOBAL.Modal_Title_Menu' : 'Menu',
    'MENUS.Label_Events' : 'EVENTS',
    'MENUS.Label_Contact' : 'CONTACT',
    'MENUS.Label_Chat' : 'Chat',
    'CHAT.Button_Text_NewChat': "New Chat",
    'CHAT.Modal_Title_NewChat': "New Chat",
};

export default english;