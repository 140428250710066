import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_USER_POSTS_DETAILED, GET_USER_POSTS_VIDEO_STATUS } from "graphql/query/user-gql";

import { CardList } from "../../card";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, ErrorResult, Loader } from "../../result";

import './user-post.css';


const normalizedArr = (postsArr) => {

    const posts = postsArr.map(item => {
        return {
            node: {
                id: item.id,
                post: { ...item }
            }
        }
    });

    const lastPostId = posts[posts.length - 1].node.post.id,
          firstPostId = posts[0].node.post.id,
          lastOrderDate = parseInt(posts[posts.length - 1].node.post.order_date),
          firstOrderDate = parseInt(posts[0].node.post.order_date);

    return {
        posts,
        lastPostId,
        firstPostId,
        lastOrderDate,
        firstOrderDate
    };
};

const UserPost = (props) => {

    const {
        postId,
        authorId,
        orderDate,
        setPageLoaded,
        postsPerPage = 10
    } = props;

    const [ hasMorePosts, setHasMorePosts ] = useState(
        {  next : true, prev: true}
    );

    const { loading, error, data, fetchMore } = useQuery(GET_USER_POSTS_DETAILED, {
        variables: {
            post_id: postId,
            author_id : authorId,
            defaultCount: postsPerPage,
            order_date: orderDate
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true,

    });

    const { data: dataVideoStatus } = useQuery(GET_USER_POSTS_VIDEO_STATUS, {
        variables: {
            post_id: postId,
            author_id : authorId,
            defaultCount: postsPerPage,
        },
        pollInterval: 30000
    });

    useEffect( () => {
            if(data){
                setPageLoaded(true)
            }
        }
    );

    if(error) {
        return (
            <ErrorResult
                error={error}
                goToLink="/"
                goToText={ <Localize>GLOBAL.Link_Text_OnError</Localize> }
            />
        );
    }

    const {
        posts,
        lastPostId,
        firstPostId,
        lastOrderDate,
        firstOrderDate
    }  = data !== undefined ?
        normalizedArr( data.userPosts ) :
        {
            posts : [],
            firstPostId : false,
            lastPostId : false,
            lastOrderDate : false,
            firstOrderDate : false
        };


    const fetchMoreAction = (direction) => {

        if(!loading &&  hasMorePosts[direction]){

            let firstItem,
                currentOffset;

            if(direction === 'prev'){
                firstItem = document.querySelector(`#post-in-timeline-${firstPostId}`);
                currentOffset = firstItem?.offsetTop - window.scrollY;
            }

            fetchMore({
                variables: {
                    post_id : direction === 'next' ? lastPostId : firstPostId,
                    paginate:  direction,
                    order_date: direction === 'next' ? lastOrderDate : firstOrderDate
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newPostList = fetchMoreResult.userPosts;

                    const newPosts = direction === 'next' ? [
                        ...data.userPosts,
                        ...newPostList,
                    ] : [
                        ...newPostList.reverse(),
                        ...data.userPosts,
                    ];

                    if(newPostList.length < postsPerPage){
                        setHasMorePosts({ ...hasMorePosts, [direction] : false } );
                    }

                    return newPosts.length ? { userPosts : [...newPosts]} : previousResult;

                }
            }).then( () => {
                if(direction === 'prev'){
                    window.scrollTo(0, firstItem.offsetTop - currentOffset);
                }
            })
                .catch((error) => {
                    errorNotification(error);
                });
        }
    };


    function updateStatuses(arr1, arr2) {
        for (const obj1 of arr1) {
            if (obj1.media && obj1.media.some(media => media.status === 'loaded')) {
                const obj2 = arr2.find(obj => obj.node.id === obj1.id);
                if (obj2) {
                    obj2.node.post.media = obj2.node.post.media.map(media => ({
                        ...media,
                        status: obj1.media.find(m => m.id === media.id && m.status === 'loaded') ? 'loaded' : media.status
                    }));
                }
            }
        }

        return arr2;
    }


    return (
        <div className="user-timeline-posts">
            { loading && data === undefined &&
                <Loader />
            }
            { data !== undefined &&
            <>
                <LoadMoreOnScroll
                    fetchAction={() => fetchMoreAction('prev')}
                    loading={loading}
                    direction="up"
                />
                <CardList
                    className="user-timeline"
                    type="timeline"
                    cardItems={ dataVideoStatus ? updateStatuses(dataVideoStatus?.userPosts, posts) : posts }
                />
                <LoadMoreOnScroll
                    fetchAction={() => fetchMoreAction('next')}
                    loading={loading}
                />
            </>
            }
        </div>
    );
};

export default UserPost;