import React, {useState} from "react";
import LinkedMention from "./linked-mention";
import Localize from "./localize";


const MoreBut = ({ setShowMore }) => {
    return (
        <span>
                <>...</>
                <button type="button"
                        className="show-more site-sub-link-color"
                        onClick={() => setShowMore(true)}
                >
                    <Localize>GLOBAL.Button_Text_More</Localize>
                </button>
            </span>
    )
};

const MoreText = (props) => {

    const [ showMore, setShowMore ] = useState(false);

    const {
        text,
        length,
    } = props;

    let readyText,
        showMoreBut = false;

    if(text === null){
        return null;
    }

    if(((length && text.length > length) || text.match(/\n/)) && !showMore) {

        if(text.match(/\n/) && text.match(/\n/).index < length){
            readyText = text.slice(0, text.indexOf('\n'));
        } else {
            readyText = text.slice(0, text.slice(0, length).lastIndexOf(' '));
        }

        showMoreBut = true;
    } else {
        readyText = text;
    }

    return (
        <div>
            <LinkedMention searchSigns={ ['@', '^', '#'] }>
                { readyText  }
            </LinkedMention>
            { showMoreBut &&
                <MoreBut setShowMore={setShowMore} />
            }
        </div>
    );
};

export default MoreText;