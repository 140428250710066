import React from "react";
import { Icon } from "antd";

const PlusNewImageSvg = (props) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform="scale(-1 1)"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FDFDFD"
        d="M-1629.412 -558.824h4117.648V6152.94H-1629.412z"
      />
      <path
        d="M-223.53 -188.236h552.942v552.942h-552.942Z"
        stroke="#A2A2A2"
        fill="#EDEDED"
        strokeDasharray={4}
        strokeWidth={2.9411764705882355}
      />
      <path
        d="M19.644 80.712V71.02c0.07 -0.064 0.158 -0.094 0.224 -0.17l11.456 -13.368 11.958 8.968a2.68 2.68 0 0 0 3.7 -0.47l17.142 -21.43 16.588 19.98v16.182zM80.714 19.644v36.492l-14.568 -17.538a2.68 2.68 0 0 0 -2.058 -0.97h-0.024a2.68 2.68 0 0 0 -2.07 1.008l-17.576 21.97 -11.886 -8.912a2.68 2.68 0 0 0 -3.638 0.394l-9.25 10.794V19.644zm2.676 -5.358H16.968a2.68 2.68 0 0 0 -2.676 2.676v66.426a2.68 2.68 0 0 0 2.676 2.68h66.43a2.68 2.68 0 0 0 2.676 -2.676V16.962a2.68 2.68 0 0 0 -2.676 -2.676"
        fill="#EB008B"
      />
    </g>
  </svg>
);

const PlusNewImageIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={PlusNewImageSvg} />;
};

export default PlusNewImageIcon;
