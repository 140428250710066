import React from "react";

import AvatarBlock from "components/avatar";
import { FollowButton } from "components/follow";
import { Localize } from "components/service";
import { userNormalMeta } from "utils";

import "./users-grid.css";


const UsersGrid = ({ users, me }) => {

    return (
        <div className="users-grid">
            { users.map( ({ node }) => {
                const {
                    id,
                    avatar,
                    nickname,
                    name,
                    initials,
                    status,
                    meta_fields
                } = node;

                if(status === "block") return null;

                const {
                    contactPhone = {},
                    contactEmail = {},
                    companyDepartment = {},
                    jobDescription = {},
                    workingOn = {}
                } = userNormalMeta(meta_fields);

                return (
                    <div className="user-grid-item border-color-light" key={id}>
                        <AvatarBlock
                            size={ 80 }
                            photoUrl={ avatar }
                            initials={ initials }
                            label={`@${nickname}`}
                            link={ `/${nickname}` }
                            blocked={ status === "block"  ? "normal" : "" }
                            text={
                                <div className="user-info">
                                    { name && <h2> {name} </h2> }
                                    <div className="user-contacts">
                                        { !!contactEmail && !!contactEmail.meta_value &&
                                            <p>
                                                <a href={`mailto:${contactEmail.meta_value}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="outer-link"
                                                >
                                                    {contactEmail.meta_value}
                                                </a>
                                            </p>
                                        }
                                        { !!contactPhone && !!contactPhone.meta_value &&
                                            <p>
                                                <a href={`tel:${contactPhone.meta_value}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="outer-link"
                                                >
                                                    {contactPhone.meta_value}
                                                </a>
                                            </p>
                                        }
                                    </div>
                                    { !!companyDepartment && !!companyDepartment.meta_value &&
                                        <p className="company-department">
                                            <Localize>{ `FORMS.Select_OptionLabel_${ companyDepartment.meta_value }` }</Localize>
                                        </p>
                                    }
                                    { !!jobDescription && !!jobDescription.meta_value &&
                                        <p className="job-description">
                                            <Localize>{ `FORMS.Select_OptionLabel_${ jobDescription.meta_value }` }</Localize>
                                        </p>
                                    }
                                    { !!workingOn && !!workingOn.meta_value &&
                                        <p className="working-on">
                                            { workingOn.meta_value }
                                        </p>
                                    }

                                    <div className="card-follow">
                                        { id !== me.id ?
                                            <FollowButton author={ node }/> :
                                            <b className="site-label-text-color">It's you</b>
                                        }
                                    </div>
                                </div>
                            }
                        />
                    </div>
                );
            })

            }
        </div>
    );
};

export default UsersGrid;