import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { Tabs } from "antd";

import { GET_ME } from "graphql/query/user-gql";

import { ProfileTabSettings, ProfileTabAdditionalInfo, ProfileTabChangePassword } from "./tabs";
import { Localize } from "components/service";

import './profile-tabs.css';


const changeTab = (history, tabKey) => {
    history.push(`/profile/${tabKey}`);
};

const ProfileTabs = ({ activeTab, history }) => {

    const { TabPane } = Tabs;

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    return (
        <div className="profile-tabs">
            <Tabs
                tabPosition="left"
                activeKey={ activeTab ? activeTab : 'settings' }
                onTabClick={ (key) => {
                    changeTab(history, key);
                }}
            >
                <TabPane
                    tab={ <Localize wrap>MENUS.Label_Settings</Localize> }
                    key="settings"
                >
                    <ProfileTabSettings me={me} />
                </TabPane>
                <TabPane
                    tab={ <Localize wrap>MENUS.Label_AdditionalInfo</Localize> }
                    key="additional-information"
                >
                    <ProfileTabAdditionalInfo me={me} />
                </TabPane>
                <TabPane
                    tab={ <Localize wrap>MENUS.Label_ChangePassword</Localize> }
                    key="change-password"
                >
                    <ProfileTabChangePassword me={me} />
                </TabPane>
            </Tabs>
        </div>
    );
};


export default ProfileTabs;