import React, { useState, useEffect } from 'react';

import SearchForm from "./search-form";
import SearchByType from "./search-by-type";

import './search.css';


const SearchBlock = ({ searchType = 'post' }) => {

    const [ searchVariables, setSearchVariables ] = useState({ type: searchType, input: '' });

    useEffect(() => {
        setSearchVariables({
            ...searchVariables,
            type: searchType
        })
        // eslint-disable-next-line
    }, [ searchType ] )

    return (
        <div className="search-holder invert-search-color">
            <SearchForm
                setSearchVariables={ setSearchVariables }
                searchVariables={ searchVariables }
            />
            <SearchByType
                type={ searchVariables.type }
                query={ searchVariables.input }
            />
        </div>
    );
};

export default SearchBlock;