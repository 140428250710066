import React from "react";
import { /*Link,*/ useHistory } from "react-router-dom";
//import {message} from "antd";

import AvatarBlock from "components/avatar";
import Icons from "components/icons";
import { initials } from "utils";

import "./chat-message-list-header.css";


const getNotMe = (users, filter_id) => {

    const user = users.filter((item) => item.id !== filter_id);

    return user[0];
};

const getUserActivityText = ({usersAmount = 0, group, isOnline, lastActiveDate }) => {

    let text = `${ usersAmount } participants`;

    if(!group){

        text = `Active ${ lastActiveDate }`;

        if(lastActiveDate === "false" || !lastActiveDate.length){
            text = "offline";
        }

        if(isOnline){
            text = "online";
        }

    }

    return text;
};

const ChatMessageListHeader = ({ chat, me, chatSlug }) => {

    const {
        group_flag : group,
        users
    } = chat || {};

    const history = useHistory();

    let user = {};

    if(users !== undefined && !group){
        user = getNotMe(users, me.id);
    }


    return (
        <div className={ `chat-messages-header ${ !group && user.is_online ? "online" : "" }`}>
            <button
                onClick={ () => history.push("/chat/") }
                className="back-button button-bg-color site-icon"
            >
                <Icons.ArrowLeft/>
            </button>
            <AvatarBlock
                photoUrl={ group ? chat.cover_url : user.avatar }
                initials={ group ? initials(chat.title) : user.initials}
                label={group ?
                        `${chat.title} ${ users.length > 2 ?
                            `and ${ users.length - 2 } more` : ""
                        }` :
                        user.name
                }
                link={ group ? false : `/${user.nickname}` }
                text={ getUserActivityText({
                    group,
                    isOnline: user.is_online,
                    usersAmount: users.length,
                    lastActiveDate: user.last_active_date
                }) }
                size={ 44 }
                shape={ group ? "square" : "round" }
            />
            {/*<Link*/}
            {/*    className="chat-settings-button site-icon"*/}
            {/*    to={`/chat/${chatSlug}/details`}*/}
            {/*    onClick={ (e) => {*/}
            {/*        e.preventDefault();*/}
            {/*        message.warning('This feature will be available in future versions.')*/}
            {/*    } }*/}
            {/*>*/}
            {/*    <Icons.Settings />*/}
            {/*</Link>*/}
        </div>
    );

};

export default ChatMessageListHeader;