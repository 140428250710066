import { gql } from "apollo-boost";

import {userFieldsShort, userMetaFields} from "./user-frgm";
import { commentsFragment } from "./comment-frgm";
import { mediaFragmentFull, mediaThumbnailFields } from "./media-frgm";
import { attachmentsFragmentFull } from "./attachments-frgm";


export const postThumbnailFragment = gql`
    fragment postThumbnailFragment on Post {
        media {
            ...mediaThumbnailFields
        }
    }
    ${ mediaThumbnailFields }
`;

export const postAuthorFragment = gql`
    fragment postAuthorFragment on Post {
        author{
            ...userFieldsShort
            meta_fields{
                ...userMetaFields
            }
            me_following
            itsMe
        }
    }
    ${ userFieldsShort }
    ${ userMetaFields }
`;

export const postFieldsData = gql`
    fragment postFieldsData on Post {
        id
        title
        description
        slug
        status
        type
        order_slug
    }
`;

export const postFieldsShort = gql`
    fragment postFieldsShort on Post {
        ...postFieldsData
        liked
        viewed
        like_count
        inCollection
        order_date
    }
    ${ postFieldsData }
`;

export const postFields = gql`
    fragment postFields on Post {
        ...postFieldsShort
        view_count
        comment_count
        attached_file_count
        media_layout
        updated_at
        created_at
    }
    ${ postFieldsShort }
`;

export const postFieldsFull = gql`
    fragment postFieldsFull on Post {
        ...postFields
        ...postAuthorFragment
        ...mediaFragmentFull
        ...attachmentsFragmentFull
        ...commentsFragment
    }
    ${ postFields }
    ${ postAuthorFragment }
    ${ mediaFragmentFull }
    ${ attachmentsFragmentFull }
    ${ commentsFragment }
    
`;