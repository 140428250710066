import React from "react";
import { Icon } from "antd";

const VideoProcessingSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    style={{
      enableBackground: "new 0 0 100 100",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{"\n\t.st0{Processing:#A2A2A2;}\n"}</style>
    <g>
      <path
        className="st0"
        d="M67,27.9l-4.3,3c-1.9-1.2-4.1-2-6.3-2.6l-0.9-5.2c0-1.4-1.1-2.5-2.5-2.5h-4.8c-1.4,0-2.5,1.1-2.5,2.5l-0.9,5.2 c-2.2,0.6-4.4,1.5-6.3,2.6l-4.3-3c-1-1-2.6-1-3.6,0L27,31.3c-1,1-1,2.6,0,3.6l3,4.4c-1.2,1.9-2,4.1-2.6,6.3l-5.2,0.9 c-1.4,0-2.5,1.1-2.5,2.5v4.8c0,1.4,1.1,2.5,2.5,2.5l5.2,0.9c0.6,2.2,1.5,4.3,2.6,6.3l-3,4.4c-1,1-1,2.6,0,3.6l3.4,3.4 c1,1,2.6,1,3.6,0l4.4-3.1c1.9,1.1,4,2,6.3,2.6l0.9,5.2c0,1.4,1.1,2.5,2.5,2.5h4.8c1.4,0,2.5-1.1,2.5-2.5l0.9-5.2 c2.2-0.6,4.3-1.5,6.3-2.6l4.4,3.1c1,1,2.6,1,3.6,0l3.4-3.4c1-1,1-2.6,0-3.6l-3-4.4c1.2-1.9,2-4,2.6-6.3l5.2-0.9 c1.4,0,2.5-1.1,2.5-2.5v-4.8c0-1.4-1.1-2.5-2.5-2.5l-5.2-0.9c-0.6-2.2-1.5-4.4-2.6-6.3l3-4.4c1-1,1-2.6,0-3.6l-3.4-3.4 C69.6,26.9,68,26.9,67,27.9z M50.5,63.3c-6.6,0-12-5.4-12-12c0-6.6,5.4-12,12-12c6.6,0,12,5.4,12,12C62.5,57.9,57.1,63.3,50.5,63.3 z"
      />
      <path
        className="st0"
        d="M92.5,33.1C92,32,90.7,31.5,89.5,32c-1.1,0.5-1.7,1.8-1.2,2.9c2.2,5.1,3.3,10.5,3.3,16.1 c0,22.9-18.6,41.5-41.5,41.5S8.7,73.9,8.7,51S27.3,9.6,50.1,9.6c2.8,0,5.5,0.3,8.2,0.8l-0.9,3.3c-0.3,0.9,0.6,1.7,1.5,1.5l5.5-1.4 l5.5-1.4c0.9-0.2,1.2-1.4,0.6-2l-4-4.1l-4-4.1c-0.7-0.7-1.8-0.4-2.1,0.5l-0.9,3.3c-3.1-0.6-6.3-1-9.4-1C24.8,5.1,4.2,25.7,4.2,51 S24.8,97,50.1,97S96.1,76.3,96.1,51C96.1,44.8,94.9,38.8,92.5,33.1z"
      />
    </g>
  </svg>
);

const VideoProcessingIcon = (props) => {
  if (props.loading) {
    return <Icon type="loading" />;
  }

  return <Icon component={VideoProcessingSvg} />;
};

export default VideoProcessingIcon;
