import {gql} from "apollo-boost";

import { pageInfoFieldsFull } from "../fragment/pagination-frgm";
import { userFieldsShort, userFields, userMetaFragment } from "../fragment/user-frgm";
import { hashtagFields } from "../fragment/hashtag-frgm";
import { collectionAuthorFragment, collectionFields } from "../fragment/collection-frgm";
import { mediaFragment } from "../fragment/media-frgm";
import {postFields, postAuthorFragment, postFieldsFull} from "../fragment/post-frgm";
import { attachmentsFieldsFull } from "../fragment/attachments-frgm";


export const SEARCH_POSTS = gql`
    query SearchPosts(
        $text: String,
        $endCursor : String,
        $top: Boolean
        $type: PostType
    ){
        searchPosts(
            text: $text,
            first: 15,
            after: $endCursor,
            top: $top
            type: $type
        ){
            pageInfo{
              ...pageInfoFieldsFull
            }
            edges{
                 node{
                    ...postFields
                    ...mediaFragment
                    ...postAuthorFragment
                }
            }
        }       
    }
    ${ pageInfoFieldsFull }
    ${ postFields }
    ${ mediaFragment }
    ${ postAuthorFragment }
`;

export const SEARCH_POSTS_NEWS = gql`
    query SearchPostsNews(
        $text: String,
        $endCursor : String,
        $top: Boolean
    ){
        searchPosts(
            text: $text,
            first: 15,
            after: $endCursor,
            top: $top
            type: news
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...postFieldsFull
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ postFieldsFull }
`;

export const SEARCH_USERS = gql`
    query SearchUsers(
        $text: String,
        $endCursor : String
        $where : QuerySearchUsersWhereWhereConditions
    ){
        searchUsers(
            text: $text
            where: $where
            first: 30
            after: $endCursor
        ){
            pageInfo{
              ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...userFields
                    ...userMeta
                    itsMe
                    me_following
                    counters : user_counters{
                        published : public_posts
                        followers
                        following
                    }
                }
            }
        }       
    }
    ${ pageInfoFieldsFull }
    ${ userFields }
    ${ userMetaFragment }
`;

export const SEARCH_HASHTAGS = gql`
    query SearchHashtags(
        $text: String,
        $orderBy: [QuerySearchHashtagsOrderByOrderByClause!],
        $endCursor : String
    ){
        searchHashtags(
            text: $text,
            first: 15,
            orderBy: $orderBy,
            after: $endCursor
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...hashtagFields
                }
            }
        }       
    }
    ${ pageInfoFieldsFull }
    ${ hashtagFields }
`;

export const SEARCH_COLLECTIONS = gql`
    query SearchCollections(
        $text: String,
        $orderBy: [QuerySearchCollectionsOrderByOrderByClause!],
        $endCursor : String
    ){
        searchCollections(
            text: $text,
            first: 15,
            orderBy: $orderBy,
            after: $endCursor
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...collectionFields
                    ...collectionAuthorFragment
                    posts(first : 4){
                        pageInfo{
                            total
                        }
                        edges{
                            node{
                                id
                                title
                                media{
                                    thumbnail
                                }
                            }
                        }
                    }
                }
            }
        }       
    }
    ${ pageInfoFieldsFull }    
    ${ collectionFields }
    ${ collectionAuthorFragment }
`;


export const SEARCH_ATTACHMENTS = gql`
    query SearchAttachments(
        $author_id : ID,
        $text : String,
        $orderBy: [QuerySearchAttachmentsOrderByOrderByClause!], 
        $endCursor : String
    ){
        searchAttachments(
            text: $text,
            author_id : $author_id,
            orderBy: $orderBy,
            after: $endCursor,
            first: 15
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...attachmentsFieldsFull
                    post{
                        id
                        slug
                        order_slug
                    }
                    author{
                        ...userFieldsShort
                    }       
                } 
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ attachmentsFieldsFull }
    ${ userFieldsShort }
`;

export const GET_SEARCH_RESULTS = gql`
    query GetSearchResults($text: String, $type: TypeSearch, $page: Int){
        search(text: $text, type: $type, page: $page, first: 12){
            paginatorInfo{
              count
              total
              lastPage
              currentPage
              hasMorePages
            }
            data{
                id
                title
                image
                short_description
                slug
            }
        }       
    }`;
