import React from 'react';
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { GET_ME } from "graphql/query/user-gql";

import { WrapColumn } from "../wraps";
import { MainRoutes } from "../routes";
import { Header, MainBarMobile } from "../menu-bar";
import { ElementVisibility } from "../service";
import { ErrorBlock, Loader } from "../result";

import './app-colors.css';
import './app.css';


const App = () => {

    const { loading, error, client } = useQuery(GET_ME);
    const history = useHistory();

    return (
        <>
            { loading &&
                <Loader type="block" />
            }
            { !loading && !error &&
                <>
                    <ElementVisibility maxWidth={768} type="toggle" comparison="<">
                        <WrapColumn position="show">
                            <MainBarMobile/>
                        </WrapColumn>
                        <WrapColumn position="hide">
                            <Header/>
                        </WrapColumn>
                    </ElementVisibility>
                    <main className="main-block">
                        <MainRoutes/>
                    </main>
                </>
            }
            { error &&
                <ErrorBlock
                    action={
                        () => {
                            localStorage.clear();
                            client.resetStore();
                            history.push("/");
                        }
                    }
                />
            }
        </>
    );
};

export default App;