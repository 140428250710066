import React from 'react';

import { AvatarThreeRow } from "../../avatar";
import Icons from "../../icons";

import './card-preview-row.css';
import {Localize} from "../../service";


const CardPreviewRow = (props) => {

    const {
        post,
        showAuthor,
        fullPath
    } = props;

    return (
        <div className={ `card-preview-row border-color-light ${ post.type }` }>
            <div className="card-data">
                <AvatarThreeRow
                    link={fullPath ? fullPath : `/post/${post.order_slug}`}
                    photoUrl={ post.media[0].thumbnail }
                    size={ 56 }
                    shape="square"
                    avatarClass={ post.type === "news" ? "company-border-color" : "border-color-light"}
                    nickname={ showAuthor && post.author.nickname }
                    title={
                        <>
                            { post.type === "news" &&
                                <span className="company-color">
                                    <Localize>POST.Avatar_NewsTitle</Localize>{ post.title ? ": " : " " }
                                </span>
                            }
                            { post.title  }
                        </>

                    }
                    text={ post.description }
                />
            </div>
            <div className="card-info">
                <div className="card-date site-sub-text-color">
                    { post.created_at }
                </div>
                <div className="card-counters site-sub-text-color">
                    <ul>
                        <li>
                            <Icons.Like filled />
                            <span>{ post.like_count }</span>
                        </li>
                        <li>
                            <Icons.Comment filled />
                            <span>{ post.comment_count }</span>
                        </li>
                        <li>
                            <Icons.MultiImage />
                            <span>{ post.media.length }</span>
                        </li>
                        <li>
                            <Icons.VideoFill />
                            <span>{ post.media.filter(({status}) => status !== null).length }</span>
                        </li>
                        <li>
                            <Icons.Document filled />
                            <span>{ post.attached_file_count }</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CardPreviewRow;