const getNow = (type = "current") => {

    let date = new Date();

    if(type === "start"){
        date.setUTCHours(0,0,0,0);
    }

    if(type === "end"){
        date.setUTCHours(23,59,59,999);
    }

    return new Date(date);

}

const past = (days = 0, date = getNow(), type = "string") => {

    date = date.setDate(date.getDate() - days);

    if(type === "string"){
        return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
    }

    return new Date(date);

}

const future = (days = 0, date = getNow(), type = "string") => {
    date = date.setDate(date.getDate() + days);

    if(type === "string"){
        return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
    }

    return date;
}



const getDate = {
    now : getNow,
    past,
    future
}

export default getDate;