import { gql } from "apollo-boost";

import { commentsFragmentPaginated } from "../fragment/comment-frgm";


export const GET_POST_COMMENTS = gql`
    query GetPostComments(
        $post_id : ID,
        $commentsCursor : String
    ){
        post(post_id : $post_id){
            id
            slug
            ...commentsFragmentPaginated
        }
    }
    ${ commentsFragmentPaginated }
`;